<template>
	<b-container class="impressum">
		<img src="../assets/images/impressum.jpg" class="w-100" />
		<div></div>
		<b-container>
			<b-row style="text-align:center">
				<div class="title-wrapper">
					<h1>{{ $t('impressum.title') }}</h1>
				</div>
			</b-row>
			<b-row style="text-align:center">
				<b-col sm="12" md="6">
					<h5>{{ $t('impressum.name') }}</h5>
					<p>Rudolf-Diesel Ring 32</p>
					<p>82054 Sauerlach</p>
					<p>E-Mail: {{ $t('impressum.email') }}</p>
				</b-col>
				<b-col sm="12" md="6">
					<p>{{ $t('impressum.leader') }}</p>
					<p>{{ $t('impressum.registration') }}</p>
					<p>{{ $t('impressum.reg-number') }}</p>
					<p>{{ $t('impressum.id-num') }}</p>
				</b-col>
			</b-row>
			<b-row class="mt-5">
				<b-col sm="12" style="text-align: center">
					<p class="hinweis">
						{{ $t('impressum.hinweis') }}
					</p>
					<h5 class="mt-5">{{ $t('impressum.creator') }}</h5>
					<p class="creator-name">
						{{ $t('impressum.creator-name') }}
					</p>
					<a href="https://www.bella-capilla.com" rel="noopener noreferrer" target="_blank">{{ $t('impressum.creator-page') }}</a>
					<p class="hinweis mt-4">
						{{ $t('impressum.hinweis-2') }}
					</p>
				</b-col>
			</b-row>
			<b-row class="button-row">
				<div class="button-wrapper">
					<router-link to="/contact">
						<b-button class="btn btn-blue btn-big">{{ $t('leistungen.button') }}</b-button>
					</router-link>
				</div>
			</b-row>
		</b-container>
	</b-container>
</template>

<script>
	export default {
		name: 'Impressum',
	};
</script>
