<template>
	<b-container class="datenschutz">
		<img src="../assets/images/munich_two.jpg" class="w-100" />
		<div></div>
		<b-container>
			<div class="title-wrapper datenschutz-title">
				<h1>{{ $t('datenschutz.title') }}</h1>
			</div>
			<b-row style="text-align:center; width:80%; margin:auto">
				<p class="mb-2">
					{{ $t('datenschutz.text-one') }}
				</p>
				<p class="mb-2">
					{{ $t('datenschutz.text-two') }}
				</p>
				<p class="mb-2">
					{{ $t('datenschutz.text-three') }}
				</p>
				<h5 class="mt-3 mb-3">{{ $t('datenschutz.begriffsbestimmungen') }}</h5>
				<p class="mb-2">
					{{ $t('datenschutz.text-four') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.aPersonen') }}
				</p>
				<p class="mb-2">
					{{ $t('datenschutz.text-five') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.bPersonen') }}
				</p>
				<p class="mb-2">
					{{ $t('datenschutz.text-six') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.cPersonen') }}
				</p>
				<p class="mb-2">
					{{ $t('datenschutz.text-seven') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.dPersonen') }}
				</p>
				<p class="mb-2">
					{{ $t('datenschutz.text-eight') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.ePersonen') }}
				</p>
				<p class="mb-2">
					{{ $t('datenschutz.text-nine') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.fPersonen') }}
				</p>
				<p class="mb-2">
					{{ $t('datenschutz.text-ten') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.gPersonen') }}
				</p>
				<p class="mb-2">
					{{ $t('datenschutz.text-11') }}
				</p>

				<p class="mb-1">
					{{ $t('datenschutz.hPersonen') }}
				</p>
				<p class="mb-2">
					{{ $t('datenschutz.text-12') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.iPersonen') }}
				</p>
				<p class="mb-2">
					{{ $t('datenschutz.text-13') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.jPersonen') }}
				</p>
				<p class="mb-2">
					{{ $t('datenschutz.text-14') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.kPersonen') }}
				</p>
				<p class="mb-2">
					{{ $t('datenschutz.text-15') }}
				</p>

				<h5 class="mb-3 mt-4">{{ $t('datenschutz.nameundAndschrift') }}</h5>

				<p>
					{{ $t('impressum.name') }}
				</p>
				<p>
					Rudolf-Diesel Ring 32
				</p>
				<p>
					82054 Sauerlach
				</p>

				<p>
					{{ $t('impressum.street-three') }}
				</p>
				<p class="mt-2">E-Mail: {{ $t('impressum.email') }}</p>
				<p class="mb-1">
					Website: www.imperiaimmobilien.de
				</p>
				<h5 class="mt-4">
					{{ $t('datenschutz.cookies') }}
				</h5>
				<p class="mb-2">
					{{ $t('datenschutz.cookies-text-1') }}
				</p>
				<p class="mb-2">
					{{ $t('datenschutz.cookies-text-2') }}
				</p>
				<p class="mb-2">
					{{ $t('datenschutz.cookies-text-3') }}
				</p>
				<p class="mb-2">
					{{ $t('datenschutz.cookies-text-4') }}
				</p>
				<h5 class="mb-1 mt-4">
					{{ $t('datenschutz.erfassung') }}
				</h5>
				<p class="mb-1">
					{{ $t('datenschutz.erfassung-text-1') }}
				</p>
				<p class="mb-2">
					{{ $t('datenschutz.erfassung-text-2') }}
				</p>
				<h5 class="mb-1 mt-4">
					{{ $t('datenschutz.routine') }}
				</h5>
				<p class="mb-1">
					{{ $t('datenschutz.routine-text-1') }}
				</p>
				<p class="mb-2">
					{{ $t('datenschutz.routine-text-2') }}
				</p>
				<h5 class="mb-1 mt-4">
					{{ $t('datenschutz.rechte') }}
				</h5>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-a-1') }}
				</p>
				<p class="mb-2">
					{{ $t('datenschutz.rechte-a-2') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-b-1') }}
				</p>
				<p class="mb-2">
					{{ $t('datenschutz.rechte-b-2') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-b-2a') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-b-2b') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-b-2c') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-b-2d') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-b-2e') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-b-2f') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-b-2g') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-b-2h') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-b-2i') }}
				</p>
				<p class="mb-2">
					{{ $t('datenschutz.rechte-b-2j') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-c-1') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-c-1a') }}
				</p>
				<p class="mb-2">
					{{ $t('datenschutz.rechte-c-1b') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-d-1') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-d-1a') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-d-1b') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-d-1c') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-d-1d') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-d-1e') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-d-1f') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-d-1g') }}
				</p>
				<p class="mb-2">
					{{ $t('datenschutz.rechte-d-1h') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-e') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-e-1a') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-e-1b') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-e-1c') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-e-1d') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-e-1e') }}
				</p>
				<p class="mb-2">
					{{ $t('datenschutz.rechte-e-1f') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-f') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-f-1') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-f-2') }}
				</p>
				<p class="mb-2">
					{{ $t('datenschutz.rechte-f-3') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-g') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-g-1') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-g-2') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-g-3') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-g-4') }}
				</p>
				<p class="mb-2">
					{{ $t('datenschutz.rechte-g-5') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-h') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-h-1') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-h-2') }}
				</p>
				<p class="mb-2">
					{{ $t('datenschutz.rechte-h-3') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-i') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.rechte-ia') }}
				</p>
				<p class="mb-2">
					{{ $t('datenschutz.rechte-ib') }}
				</p>
				<h5 class="mb-1 mt-4">
					{{ $t('datenschutz.datenschutzBewerbung') }}
				</h5>
				<p class="mb-1">
					{{ $t('datenschutz.datenschutzBewerbung-1') }}
				</p>
				<p class="mb-2">
					{{ $t('datenschutz.datenschutzBewerbung-2') }}
				</p>
				<h5 class="mb-1 mt-4">
					{{ $t('datenschutz.datenschutzFbk') }}
				</h5>
				<p class="mb-1">
					{{ $t('datenschutz.datenschutzFbk-1') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.datenschutzFbk-2') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.datenschutzFbk-3') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.datenschutzFbk-4') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.datenschutzFbk-5') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.datenschutzFbk-6') }}
				</p>
				<p class="mb-2">
					{{ $t('datenschutz.datenschutzFbk-7') }}
				</p>
				<h5 class="mb-1 mt-4">
					{{ $t('datenschutz.datenbestimmung') }}
				</h5>
				<p class="mb-1">
					{{ $t('datenschutz.datenbestimmung-1') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.datenbestimmung-2') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.datenbestimmung-3') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.datenbestimmung-4') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.datenbestimmung-5') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.datenbestimmung-6') }}
				</p>
				<p class="mb-2">
					{{ $t('datenschutz.datenbestimmung-7') }}
				</p>
				<h5 class="mb-1 mt-4">
					{{ $t('datenschutz.rechtsgrundlage') }}
				</h5>
				<p class="mb-2">
					{{ $t('datenschutz.rechtsgrundlage-1') }}
				</p>
				<h5 class="mb-1 mt-4">
					{{ $t('datenschutz.berechtigeInteresse') }}
				</h5>
				<p class="mb-2">
					{{ $t('datenschutz.berechtigeInteresse-1') }}
				</p>
				<h5 class="mb-1 mt-4">
					{{ $t('datenschutz.dauer') }}
				</h5>
				<p class="mb-2">
					{{ $t('datenschutz.dauer-1') }}
				</p>
				<h5 class="mb-1 mt-4">
					{{ $t('datenschutz.gesetzliche') }}
				</h5>
				<p class="mb-1">
					{{ $t('datenschutz.gesetzliche-1') }}
				</p>
				<p class="mb-2">
					{{ $t('datenschutz.gesetzliche-2') }}
				</p>
				<h5 class="mb-1 mt-4">
					{{ $t('datenschutz.bestehen') }}
				</h5>
				<p class="mb-1">
					{{ $t('datenschutz.bestehen-1') }}
				</p>
				<p class="mb-1">
					{{ $t('datenschutz.bestehen-2') }}
				</p>
			</b-row>
			<b-row class="button-row">
				<div class="button-wrapper">
					<router-link to="/contact">
						<b-button class="btn btn-blue btn-big">{{ $t('leistungen.button') }}</b-button>
					</router-link>
				</div>
			</b-row>
		</b-container>
	</b-container>
</template>

<script>
	export default {
		name: 'Datenschutz',
	};
</script>
