<template>
	<b-container class="contact" data-aos="fade">
		<img src="../assets/images/contact.jpg" class="w-100" />
		<div class="text-wrapper ">
			<div class="title-wrapper small-title">
				<h1>{{ $t('contact.title') }}</h1>
			</div>
			<p style="text-align:center">
				{{ $t('contact.subtitle') }}
			</p>
		</div>
		<b-row class="my-5">
			<b-col sm="12" md="8" lg="6" style="margin:auto">
				<b-form @submit="validateForm" v-if="show">
					<b-form-group id="input-group-1" label="Email:" label-for="input-1" description="Wir teilen Ihre Daten niemals mit dritten.">
						<b-form-input id="input-1" v-model="email" type="email" name="email" placeholder="Email eingeben..." required></b-form-input>
					</b-form-group>

					<b-form-group id="input-group-2" label="Name:" label-for="input-2" class="mt-4">
						<b-form-input id="input-2" v-model="name" name="name" placeholder="Name eingeben..." required></b-form-input>
					</b-form-group>

					<label for="tel" class="mt-4">Telefonnummer:</label>
					<b-form-input id="tel" type="tel" name="tel" placeholder="Telefonnummer eingeben..." v-model="tel"></b-form-input>
					<div>
						<p class="error" v-if="validationFailed && errorsFound.includes('tel')">{{ errors.tel }}</p>
					</div>
					<div class="text-wrapper-input mt-4">
						<label for="textarea">Nachricht:</label>
						<p class="limit-text">
							<span :class="{ 'too-short': text.length < 100 || text.length > 2000 }">{{ text.length }}</span>
							/2000
						</p>
					</div>
					<b-form-textarea id="textarea" v-model="text" name="text" placeholder="Ihre Nachricht an uns... " rows="3" max-rows="6"></b-form-textarea>
					<div v-if="validationFailed === true && errorsFound.includes('text')">
						<p class="error">{{ errors.text }}</p>
					</div>
					<b-form-group id="input-group-4" label="Bevorzugte Kontaktaufnahme:" label-for="input-group-4" class="mt-4">
						<b-form-checkbox-group v-model="checked" name="checked" id="checkboxes-4">
							<b-form-checkbox value="tel">Telefon</b-form-checkbox>
							<b-form-checkbox value="email">Email</b-form-checkbox>
						</b-form-checkbox-group>
					</b-form-group>
					<div class="mt-4 d-flex justify-content-center">
						<div></div>
						<b-button class="btn btn-blue btn-medium mr-2 " type="submit">Absenden</b-button>
					</div>
				</b-form>
			</b-col>
		</b-row>
		<b-modal v-model="showThankYou" id="thankyou" centered title="Vielen Dank für Ihre Nachricht." hide-footer>
			<b-row style="justify-content: center">
				<p class="my-4">
					Ihre Nachricht wurde erfolgreich gesendet.
				</p>
			</b-row>
			<b-row style="justify-content: center">
				<b-button size="sm" class="btn btn-blue btn-medium-big float-right" @click="showThankYou = false">
					Schließen
				</b-button>
			</b-row>
		</b-modal>
		<b-modal v-model="showError" id="thankyou" centered title="Es war nicht möglich, Ihre Nachricht zu senden." hide-footer>
			<b-row style="justify-content: center">
				<p class="my-4">
					Etwas ist schief gelaufen. Bitte versuchen sie später noch mal.
				</p>
			</b-row>
			<b-row style="justify-content: center">
				<b-button size="sm" class="btn btn-blue btn-medium-big float-right" @click="showError = false">
					Schließen
				</b-button>
			</b-row>
		</b-modal>
	</b-container>
</template>
<script>
	import emailjs from 'emailjs-com';

	export default {
		name: 'Contact',
		data() {
			return {
				text: '',
				email: '',
				name: '',
				tel: '',
				checked: [],

				show: true,
				showThankYou: false,
				showError: false,
				validationFailed: false,
				errors: {
					text: 'Ihre Nachricht ist zu kurz, bitte fügen Sie mehr Details hinzu. Vielen Dank.',
					tel: 'Ihr Eingabe ist zu kurz, bitte überprüfen Sie erneut.',
				},
				errorsFound: [],
			};
		},

		methods: {
			validateForm(e) {
				e.preventDefault();
				this.errorsFound = [];

				let valid = true;
				// Validate Message Length
				if (this.text.length < 100 || this.text.length > 2000) {
					valid = false;
					this.$set(this.errorsFound, 0, 'text');
				}

				// Validate Tel
				if (this.tel.length > 16) {
					valid = false;
					this.errorsFound.push('tel');
				}

				if (valid) {
					this.onSubmit(e);
				} else {
					this.validationFailed = true;
				}
			},
			onSubmit(e) {
				console.log('sending');
				try {
					emailjs.sendForm('service_gqsfprg', 'template_nikqev6', e.target, 'user_sMt0J0UZuZTcEhDRhI8Zm').then(
						result => {
							console.log('SUCCESS!', result.status, result.text);
						},
						error => {
							console.log('FAILED...', error);
						},
					);
					this.onReset();
					this.showThankYou = true;
				} catch (error) {
					console.log({ error });
					this.showError = true;
				}
			},

			onReset() {
				// Reset our form values
				this.email = '';
				this.name = '';
				this.tel = '';
				this.checked = [];
				this.text = '';
				this.errorsFound = [];
				// Trick to reset/clear native browser form validation state
				this.show = false;
				this.$nextTick(() => {
					this.show = true;
				});
			},
		},
	};
</script>
