<template>
	<b-container class="unternehmen" data-aos="fade">
		<img src="../assets/images/unternehmen_between.jpg" class="w-100" />

		<div class="text-wrapper">
			<div class="title-wrapper medium-title">
				<h1>{{ $t('unternehmen.title') }}</h1>
			</div>
			<p>
				<!-- {{ $t('unternehmen.subtitle') }} -->
				Die IMPERIA IMMOBILIEN GmbH ist Ihr verlässlicher Partner für erstklassige Immobilieninvestitionen in aufstrebenden deutschen Metropolregionen. Mit unserem Hauptsitz in München und einem weitreichenden Netzwerk von Experten im gesamten DACH-Raum bieten wir Ihnen umfassende Dienstleistungen entlang des gesamten Immobilieninvestitionsprozesses.<br><br>
				Unsere Expertise erstreckt sich von der sorgfältigen Standortbewertung bis hin zur detaillierten Objektprüfung und dem erfolgreichen Verkauf. Dabei legen wir besonderen Wert auf Standorte mit hohem Entwicklungspotenzial und Synergieeffekten, um Ihnen die besten Investitionsmöglichkeiten zu bieten.<br><br>
				Wir begleiten unsere Geschäftspartner und Kunden mit nachweislicher Kompetenz und erstklassigem Service von der ersten Besichtigung bis zur finalen Wohnungsübergabe. Unser engagiertes Team steht Ihnen auch nach Abschluss des Verkaufsprozesses weiterhin zur Verfügung, um sicherzustellen, dass Ihre Investition erfolgreich und nachhaltig ist.<br><br>			
				Vertrauen Sie auf die Erfahrung und Professionalität der IMPERIA IMMOBILIEN GmbH für Ihre nächsten Immobilieninvestitionen.
			</p>
		</div>
		<!-- Timo -->
		<div class="profile-section">
			<div style="margin-top:100px">
				<div v-if="windowWidth < 1200" class="title-wrapper medium-title">
					<h1>{{ $t('home.profile-section.title-timo') }}</h1>
				</div>
				<b-row style="align-items: center;">
					<b-col sm="12" lg="6" class="photo-col" style="display:flex;justify-content:center;">
						<img v-if="windowWidth < 1200" src="../assets/images/team/timo_luis.jpg" style="width:80%;" />
						<img v-else src="../assets/images/team/timo_luis.jpg" height="700" />
					</b-col>
					<b-col sm="12" lg="6">
						<div v-if="!(windowWidth < 1200)" class="title-wrapper">
							<h1>{{ $t('home.profile-section.title-timo') }}</h1>
						</div>
						<p class="about-text first">{{ $t('home.profile-section.about-1') }}</p>
						<p class="about-text second">{{ $t('home.profile-section.about-2') }}</p>
						<p v-if="!(windowWidth < 1200)" class="about-text prelast">{{ $t('home.profile-section.about-3') }}</p>

						<p v-if="!(windowWidth < 1200)" class="about-text last">{{ $t('home.profile-section.about-4') }}</p>
					</b-col>
					<b-col sm="12">
						<p v-if="windowWidth < 1200" class="about-text prelast">{{ $t('home.profile-section.about-3') }}</p>
						<p v-if="windowWidth < 1200" class="about-text last">{{ $t('home.profile-section.about-4') }}</p>
					</b-col>
				</b-row>
			</div>
			<!-- Andreas -->
			<div style="margin-top:100px">
				<!-- Mobile Title -->
				<div v-if="windowWidth < 1200" class="title-wrapper medium-title">
					<h1>{{ $t('home.profile-section.title-andreas') }}</h1>
				</div>
				<b-row style="align-items: center;">
					<b-col v-if="windowWidth < 978" sm="12" class="photo-col" style="display:flex;justify-content:center;">
						<img src="../assets/images/team/andreas_fauster.jpg" style="width:80%;" />
					</b-col>
					<b-col sm="12" lg="6">
						<!-- Desktop title -->
						<div v-if="!(windowWidth < 1200)" class="title-wrapper">
							<h1>{{ $t('home.profile-section.title-andreas') }}</h1>
						</div>
						<!-- Mobile picture middle -->
						<!-- <b-col v-if="windowWidth < 1200" sm="6" class="photo-col" style="margin-top:50px;display:flex;justify-content:center;">
							<img src="../assets/images/andreas_mobile.jpg" style="width:80%;" />
						</b-col> -->
						<p class="about-text first">{{ $t('home.profile-section.about-5') }}</p>
						<p class="about-text second">{{ $t('home.profile-section.about-6') }}</p>

						<p v-if="!(windowWidth < 1200)" class="about-text last">{{ $t('home.profile-section.about-7') }}</p>
					</b-col>
					<!-- <b-col v-if="(windowWidth < 1200) & (windowWidth > 979)" sm="12" lg="6" class="photo-col" style="display:flex;justify-content:center;">
						<img src="../assets/images/andreas_mobile.jpg" style="width:80%;" />
					</b-col> -->
					<b-col v-if="windowWidth > 978" sm="6" class="photo-col" style="display:flex;justify-content:center;margin-top:50px">
						<img src="../assets/images/team/andreas_fauster.jpg" height="700" />
					</b-col>
					<!-- Last paragraph mobile -->
					<b-col sm="12" v-if="windowWidth < 1200">
						<p class="about-text last">{{ $t('home.profile-section.about-7') }}</p>
					</b-col>
				</b-row>
			</div>

			<div class="team-grid">
				<!--TEAM BOX-->
				<div class="team-box">
					<img src="../assets/images/team/luana_niedermoser.jpg" alt="">
					<strong>Luana Niedermoser</strong>
					<p>Assistenz der Geschäftsführung</p>
				</div>
				<!--TEAM BOX-->
				<div class="team-box">
					<img src="../assets/images/team/janine_kovar.jpg" alt="">
					<strong>Janine Kovar </strong>
					<p>Objektmanagerin</p>
				</div>
				<!--TEAM BOX-->
				<div class="team-box">
					<img src="../assets/images/team/melek_erkol.jpg" alt="">
					<strong>Melek Erkol</strong>
					<p>Head of Design</p>
				</div>
				<!--TEAM BOX-->
				<div class="team-box">
					<img src="../assets/images/team/nico_hermann.jpg" alt="">
					<strong>Nico Hermann</strong>
					<p>Head of Finance</p>
				</div>
				<!--TEAM BOX-->
				<div class="team-box">
					<img src="../assets/images/team/max_griesgraber.jpg" alt="">
					<strong>Max Griesgraber</strong>
					<p>Teamleitung</p>
				</div>
		
				<!--TEAM BOX-->
				<div class="team-box">
					<img src="../assets/images/team/placeholder.jpg" alt="">
					<strong>Max Amendt</strong>
					<p>Backoffice Manager</p>
				</div>
				<!--TEAM BOX-->
				<div class="team-box">
					<img src="../assets/images/team/hannes_ambros.jpg" alt="">
					<strong>Hannes Ambros</strong>
					<p>Berater</p>
				</div>
				<!--TEAM BOX-->
				<div class="team-box">
					<img src="../assets/images/team/luisa_padrok.jpg" alt="">
					<strong>Luisa Padrok</strong>
					<p>Beraterin</p>
				</div>
				<!--TEAM BOX-->
				<div class="team-box">
					<img src="../assets/images/team/sam-al-mandawi.jpg" alt="">
					<strong>Sam Al-Mandawi</strong>
					<p>Berater</p>
				</div>
				<!--TEAM BOX-->
				<div class="team-box">
					<img src="../assets/images/team/raphael_großteßner-hain.jpg" alt="">
					<strong>Raphael Großteßner-Hain</strong>
					<p>Berater</p>
				</div>
				<!--TEAM BOX-->
				<div class="team-box">
					<img src="../assets/images/team/placeholder.jpg" alt="">
					<strong>Pascal Rudroff</strong>
					<p>Berater</p>
				</div>
				<!--TEAM BOX-->
				<div class="team-box">
					<img src="../assets/images/team/placeholder.jpg" alt="">
					<strong>Luca Banzer</strong>
					<p>Assistent</p>
				</div>
			</div>
		</div>
		<div style="margin-top:100px">
			<div class="title-wrapper warum medium-title">
				<h1>{{ $t('unternehmen.warum') }}</h1>
			</div>
			<p class="text-wrapper">
				{{ $t('unternehmen.warum-text') }}
			</p>
		</div>
		<div class="mt-5">
			<img src="../assets/images/realestate.jpg" class="w-100" />
		</div>
		<div>
			<div class="title-wrapper small-title mt-5">
				<h1>{{ $t('unternehmen.vision') }}</h1>
			</div>
			<p class="text-wrapper">
				{{ $t('unternehmen.vision-text') }}
			</p>
		</div>
		<b-row class="button-row">
			<router-link to="/contact">
				<b-button class="btn btn-blue btn-big">Jetzt Anfragen</b-button>
			</router-link>
		</b-row>
	</b-container>
</template>
<script>
	export default {
		name: 'Company',
		data: () => ({
			windowWidth: window.innerWidth,
		}),
		mounted() {
			window.onresize = () => {
				this.windowWidth = window.innerWidth;
			};
		},
	};
</script>
