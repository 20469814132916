<template>
	<b-container class="anlage" data-aos="fade">
		<div class="img-wrapper-main">
			<img src="../assets/images/possibilities.jpg" class="w-100" />

			<h1 class="image-over">{{ $t('immo-als-anlage.img-texts.text-one') }}</h1>
			<h2 class="image-over">{{ $t('immo-als-anlage.img-texts.text-two') }}</h2>
			<h3 class="image-over">{{ $t('immo-als-anlage.img-texts.text-three') }}</h3>
		</div>
		<div>
			<div class="title-wrapper big-title">
				<h1>{{ $t('home.navigation.links.first') }}</h1>
			</div>
			<p>
				{{ $t('immo-als-anlage.tabs-titles.main-text') }}
			</p>
			<div>
				<b-tabs nav-wrapper-class="sticky-top" justified>
					<!-- First tab -->
					<b-tab :title="$t('immo-als-anlage.tabs-titles.first-tab')" active>
						<img src="../assets/images/property_small.jpg" class="w-100" />
						<div class="title-wrapper big-title">
							<h1>{{ $t('immo-als-anlage.tabs-titles.first-tab') }}</h1>
						</div>
						<div class="intro-text">
							<p>
								{{ $t('immo-als-anlage.markt-moglichkeiten.first-text') }}
							</p>
						</div>
						<div class="cards-wrapper">
							<b-row style="justify-content: center">
								<b-col sm="12" md="6" lg="4">
									<b-card title="Der Klassiker: Das Sparbuch" :img-src="require('../assets/images/sparbuch.jpg')" img-alt="Image" img-top tag="article">
										<b-card-text>
											{{ $t('immo-als-anlage.markt-moglichkeiten.first-card.text') }}
										</b-card-text>
										<div class="button-container">
											<router-link to="/contact">
												<b-button class="btn btn-blue btn-big">{{ $t('immo-als-anlage.markt-moglichkeiten.first-card.button') }}</b-button>
											</router-link>
										</div>
									</b-card>
								</b-col>
								<b-col sm="12" md="6" lg="4">
									<b-card
										title="Die Komplizierten: Beteiligungen und Unternehmensinvestitionen"
										:img-src="require('../assets/images/investment.jpg')"
										img-alt="Image"
										img-top
										tag="article"
									>
										<b-card-text>
											{{ $t('immo-als-anlage.markt-moglichkeiten.second-card.text') }}
										</b-card-text>

										<div class="button-container">
											<router-link to="/contact">
												<b-button class="btn btn-blue btn-big">{{ $t('immo-als-anlage.markt-moglichkeiten.second-card.button') }}</b-button>
											</router-link>
										</div>
									</b-card>
								</b-col>
							</b-row>

							<b-row style="justify-content: center">
								<b-col sm="12" md="6" lg="4">
									<b-card :title="$t('immo-als-anlage.markt-moglichkeiten.fourth-card.title')" :img-src="require('../assets/images/gold.jpg')" img-alt="Image" img-top tag="article">
										<b-card-text>
											{{ $t('immo-als-anlage.markt-moglichkeiten.fourth-card.text') }}
										</b-card-text>

										<div class="button-container">
											<router-link to="/contact">
												<b-button class="btn btn-blue btn-big">{{ $t('immo-als-anlage.markt-moglichkeiten.fourth-card.button') }}</b-button>
											</router-link>
										</div>
									</b-card>
								</b-col>
								<b-col sm="12" md="6" lg="4">
									<b-card :title="$t('immo-als-anlage.markt-moglichkeiten.fifth-card.title')" :img-src="require('../assets/images/aktien.jpg')" img-alt="Image" img-top tag="article">
										<b-card-text>
											{{ $t('immo-als-anlage.markt-moglichkeiten.fifth-card.text') }}
										</b-card-text>

										<div class="button-container">
											<router-link to="/contact">
												<b-button class="btn btn-blue btn-big">{{ $t('immo-als-anlage.markt-moglichkeiten.fourth-card.button') }}</b-button>
											</router-link>
										</div>
									</b-card>
								</b-col>
							</b-row>
							<b-row style="justify-content: center">
								<b-col sm="12" md="6" lg="4">
									<b-card
										:title="$t('immo-als-anlage.markt-moglichkeiten.third-card.title')"
										:img-src="require('../assets/images/lebensversicherung.jpg')"
										img-alt="Image"
										img-top
										tag="article"
									>
										<b-card-text>
											{{ $t('immo-als-anlage.markt-moglichkeiten.third-card.text') }}
										</b-card-text>

										<div class="button-container">
											<router-link to="/contact">
												<b-button class="btn btn-blue btn-big">{{ $t('immo-als-anlage.markt-moglichkeiten.third-card.button') }}</b-button>
											</router-link>
										</div>
									</b-card>
								</b-col>
							</b-row>
						</div>
					</b-tab>
					<!-- Second Tab -->
					<b-tab :title="$t('immo-als-anlage.tabs-titles.second-tab')">
						<img src="../assets/images/house_three.jpg" class="w-100" />
						<div class="title-wrapper my-5 big-title">
							<h1>{{ $t('immo-als-anlage.tabs-titles.second-tab') }}</h1>
						</div>
						<div>
							<b-row class="last-section-anlage">
								<b-col sm="12" md="6">
									<h2>{{ $t('immo-als-anlage.immoanlagen.left-subtext-title') }}</h2>
									<p>
										{{ $t('immo-als-anlage.immoanlagen.left-subtext-text') }}
									</p>
								</b-col>
								<b-col sm="12" md="6">
									<h2>{{ $t('immo-als-anlage.immoanlagen.right-subtext-title') }}</h2>
									<p>
										{{ $t('immo-als-anlage.immoanlagen.right-subtext-text') }}
									</p>
								</b-col>
							</b-row>
						</div>
						<b-row class="mt-5">
							<b-col style="display:flex; justify-content: center">
								<img src="../assets/images/graph.jpg" class="img-fluid" />
							</b-col>
						</b-row>
						<b-row class="items-wrapper-text">
							<b-col sm="12" md="6" class="items-wrapper">
								<div>
									<div class="subtitle-wrapper short-border">
										<h5>{{ $t('immo-als-anlage.immoanlagen.items.item-one.title') }}</h5>
									</div>
									<p>{{ $t('immo-als-anlage.immoanlagen.items.item-one.text') }}</p>
								</div>
								<div>
									<div class="subtitle-wrapper">
										<h5>{{ $t('immo-als-anlage.immoanlagen.items.item-two.title') }}</h5>
									</div>
									<p>{{ $t('immo-als-anlage.immoanlagen.items.item-two.text') }}</p>
								</div>
								<div>
									<div class="subtitle-wrapper">
										<h5>{{ $t('immo-als-anlage.immoanlagen.items.item-three.title') }}</h5>
									</div>
									<p>{{ $t('immo-als-anlage.immoanlagen.items.item-three.text') }}</p>
								</div>
								<div>
									<div class="subtitle-wrapper">
										<h5 v-if="windowWidth > 1200">{{ $t('immo-als-anlage.immoanlagen.items.item-four.title') }}</h5>
										<h5 v-if="windowWidth < 1200" class="no-border">{{ $t('immo-als-anlage.immoanlagen.items.item-four.title-1') }}</h5>
										<h5 v-if="windowWidth < 1200">{{ $t('immo-als-anlage.immoanlagen.items.item-four.title-2') }}</h5>
									</div>
									<p>{{ $t('immo-als-anlage.immoanlagen.items.item-four.text') }}</p>
								</div>
								<div>
									<div class="subtitle-wrapper">
										<h5>{{ $t('immo-als-anlage.immoanlagen.items.item-five.title') }}</h5>
									</div>
									<p>{{ $t('immo-als-anlage.immoanlagen.items.item-five.text') }}</p>
								</div>
							</b-col>
							<b-col sm="12" md="6" class="items-wrapper">
								<div class="second-col">
									<div class="subtitle-wrapper">
										<h5>{{ $t('immo-als-anlage.immoanlagen.items.item-six.title') }}</h5>
									</div>
									<p>
										{{ $t('immo-als-anlage.immoanlagen.items.item-six.text') }}
									</p>
								</div>
								<div>
									<div class="subtitle-wrapper">
										<h5>{{ $t('immo-als-anlage.immoanlagen.items.item-seven.title') }}</h5>
									</div>
									<p>{{ $t('immo-als-anlage.immoanlagen.items.item-seven.text') }}</p>
								</div>
								<div>
									<div class="subtitle-wrapper">
										<h5>{{ $t('immo-als-anlage.immoanlagen.items.item-eight.title') }}</h5>
									</div>
									<p>{{ $t('immo-als-anlage.immoanlagen.items.item-eight.text') }}</p>
								</div>
								<div>
									<div class="subtitle-wrapper">
										<h5>{{ $t('immo-als-anlage.immoanlagen.items.item-nine.title') }}</h5>
									</div>
									<p>
										{{ $t('immo-als-anlage.immoanlagen.items.item-nine.text') }}
									</p>
								</div>
							</b-col>
						</b-row>
						<b-row class="button-row">
							<div class="button-wrapper">
								<router-link to="/contact">
									<b-button class="btn btn-blue btn-big">{{ $t('leistungen.button') }}</b-button>
								</router-link>
							</div>
						</b-row>
					</b-tab>
					<!-- Third Tab -->
					<b-tab :title="$t('immo-als-anlage.tabs-titles.third-tab')" class="calculation-example">
						<img src="../assets/images/calculator.jpg" class="w-100" />
						<div class="title-wrapper mt-5 big-title">
							<h1>{{ $t('immo-als-anlage.tabs-titles.third-tab') }}</h1>
						</div>
						<p class="text-wrapper">
							<!-- {{ $t('immo-als-anlage.rechenbeispiel.text') }} -->
							Mal angenommen eine Wohnung kostet Sie insgesamt 150.000€. Dann brauchen Sie ca. 10.500€ Eigenkapital. Die Wohnung an sich finanzieren Sie zu 100% über die Bank. Ihre Mieter zahlen Ihnen den Großteil Ihrer Wohnung ab.
						</p>
						<b-row>
							<b-col sm="12" md="6">
								<div class="calc-title-wrapper">
									<h2>Ihre Mieteinnahmen</h2>
								</div>
								<div class="calc-pos-wrap">
									<div class="calc-wrapper">
										<h5 class="left">Miete</h5>
										<p class="right pos">+ 500 EUR</p>
										<p class="bottom">Sie erhalten monatliche Mieteinnahmen von Ihrem Mieter dafür, dass er in Ihrer Wohnung wohnen darf.</p>
									</div>
									<div class="calc-wrapper">
										<h5 class="left">Steuervorteil</h5>
										<p class="right pos">- EUR</p>
										<p class="bottom">
											Als Vermieter sind Sie Unternehmer und können diverse Ausgaben steuerliche geltend machen. Wir haben den Steuervorteil bewusst ausgeblendet um die Rechnung
											so einfach wie möglich zu halten.
										</p>
									</div>
								</div>
							</b-col>
							<b-col sm="12" md="6">
								<div class="calc-title-wrapper">
									<h2>Ihre Monatlichen Objektkosten</h2>
								</div>
								<div class="calc-negative-wrap">
									<div class="calc-wrapper">
										<h5 class="left">Zinsen</h5>
										<p class="right neg">- 500 EUR</p>
										<p class="bottom">
											Für das Darlehen verlangt die Bank Zinsen. In diesem Beispiel gehen wir von 2% Zins aus. In Euro entspricht das der hier aufgeführten monatlichen Rate.
										</p>
									</div>
									<div class="calc-wrapper">
										<h5 class="left">Tilgung</h5>
										<p class="right neg">- 190 EUR</p>
										<p class="bottom">
											Die Bank möchte, dass sich Ihr Darlehen über die Zeit abbezahlt. Dies geschieht über die Tilgung. In diesem Beispiel gehen wir von 1.5% Tilgung aus. In Euro
											entspricht ungefähr der hier aufgeführten monatlichen Rate.
										</p>
									</div>
									<div class="calc-wrapper">
										<h5 class="left">Verwaltung</h5>
										<p class="right neg">- 30 EUR</p>
										<p class="bottom">
											Damit Sie sich nicht um alles selbst kümmern müssen, beauftragen Sie eine Hausverwaltung. Diese kümmert sich um Objekt, Mieter und Abrechnung.
										</p>
									</div>
									<div class="calc-wrapper">
										<h5 class="left">Rücklage</h5>
										<p class="right neg">- 50 EUR</p>
										<p class="bottom">
											Sie bilden Rücklagen für die Instandhaltung des Objektes. Falls zum Beispiel mal die Heizung oder ähnliches ausgetauscht werden muss.
										</p>
									</div>
								</div>
							</b-col>
						</b-row>
						<b-row>
							<b-col>
								<div class="total-wrap">
									<p class="left">Gesamteinnahmen</p>
									<p class="right pos mb-0">+ 550 EUR</p>
								</div>
							</b-col>
							<b-col>
								<div class="total-wrap">
									<p class="left">Gesamtkosten</p>
									<p class="right neg mb-0">- 770 EUR</p>
								</div>
							</b-col>
						</b-row>
						<hr />
						<b-row class="final-calculation">
							<b-col>
								<div>
									<p class="left mb-0">Gesamteinnahmen</p>
								</div>
							</b-col>

							<b-col>
								<div>
									<p class="right pos mb-0">+ 500 EUR</p>
								</div>
							</b-col>
						</b-row>
						<b-row class="final-calculation">
							<b-col>
								<div>
									<p class="left mb-0">Gesamtkosten</p>
								</div>
							</b-col>
							<b-col>
								<p class="right neg mb-0">- 770 EUR</p>
							</b-col>
						</b-row>
						<hr />
						<b-row class="final-calculation">
							<b-col>
								<div>
									<p class="left mb-0">Eigenanteil zum Vermögensaufbau</p>
									<p class="right neg mb-0">220 EUR</p>
								</div>
							</b-col>
						</b-row>
						<hr class="final-calc" />
						<p>Hinweis: Dieses Rechenbeispiel ist rein fiktiv und ohne jegliche Gewähr.</p>
						<!-- <p class="mb-5">
							Von den 250€ Tilgung für Ihren Vermögensaufbau zahlen Sie nur 80€ selbst. Ihre Mieter zahlen Ihnen den Großteil Ihrer Immobilie ab und beteiligen sich somit an Ihrem
							Vermögensaufbau.
						</p> -->
						<b-row class="button-row">
							<div class="button-wrapper">
								<router-link to="/contact">
									<b-button class="btn btn-blue btn-big">{{ $t('leistungen.button') }}</b-button>
								</router-link>
							</div>
						</b-row>
					</b-tab>
					<!-- Fourth Tab -->
					<b-tab :title="$t('immo-als-anlage.tabs-titles.fourth-tab')" class="chances-wrapper">
						<div class="title-wrapper my-5">
							<h1>{{ $t('immo-als-anlage.tabs-titles.fourth-tab') }}</h1>
						</div>
						<div>
							<p class="intro-text">
								{{ $t('immo-als-anlage.chancen-risiken.subtitle') }}
							</p>
							<p class="sub-text">{{ $t('immo-als-anlage.chancen-risiken.subtitle-two') }}</p>
						</div>
						<b-row class="" v-if="windowWidth > 1200">
							<b-col sm="12" lg="6">
								<img src="../assets/images/woodhouse.jpg" class="w-100" />
							</b-col>
							<b-col sm="12" lg="6">
								<div class="mt-5">
									<h4>{{ $t('immo-als-anlage.chancen-risiken.first-title') }}</h4>
									<p>
										{{ $t('immo-als-anlage.chancen-risiken.first-text') }}
									</p>
								</div>
								<div class="mt-5">
									<h4>{{ $t('immo-als-anlage.chancen-risiken.second-title') }}</h4>
									<p>
										{{ $t('immo-als-anlage.chancen-risiken.second-text') }}
									</p>
								</div>
							</b-col>
						</b-row>
						<b-row class="" v-else>
							<b-col sm="12" lg="6">
								<img src="../assets/images/woodhouse2.jpg" class="w-100" />
							</b-col>
							<b-col sm="12" lg="6">
								<div class="mt-5">
									<h3>{{ $t('immo-als-anlage.chancen-risiken.first-title') }}</h3>
									<p>
										{{ $t('immo-als-anlage.chancen-risiken.first-text') }}
									</p>
								</div>
							</b-col>
						</b-row>
						<b-row>
							<b-col sm="12" md="6">
								<div class="mt-5">
									<h3>{{ $t('immo-als-anlage.chancen-risiken.third-title') }}</h3>
									<p>
										{{ $t('immo-als-anlage.chancen-risiken.third-text') }}
									</p>
								</div>
							</b-col>
							<b-col>
								<div class="mt-5">
									<h3>{{ $t('immo-als-anlage.chancen-risiken.fourth-title') }}</h3>
									<p>
										{{ $t('immo-als-anlage.chancen-risiken.fourth-text') }}
									</p>
								</div>
							</b-col>
						</b-row>
						<b-row>
							<b-col sm="12" md="6">
								<div class="mt-5">
									<h3>{{ $t('immo-als-anlage.chancen-risiken.five-title') }}</h3>
									<p>
										{{ $t('immo-als-anlage.chancen-risiken.five-text') }}
									</p>
								</div>
							</b-col>
							<b-col>
								<div class="mt-5">
									<h3>{{ $t('immo-als-anlage.chancen-risiken.six-title') }}</h3>
									<p>
										{{ $t('immo-als-anlage.chancen-risiken.six-text') }}
									</p>
								</div>
							</b-col>
						</b-row>
						<b-row v-if="windowWidth < 1200">
							<div class="mt-5">
								<h3>{{ $t('immo-als-anlage.chancen-risiken.second-title') }}</h3>
								<p>
									{{ $t('immo-als-anlage.chancen-risiken.second-text') }}
								</p>
							</div>
						</b-row>
						<b-row class="button-row">
							<div class="button-wrapper">
								<router-link to="/contact">
									<b-button class="btn btn-blue btn-big">{{ $t('leistungen.button') }}</b-button>
								</router-link>
							</div>
						</b-row>
					</b-tab>
				</b-tabs>
			</div>
		</div>
	</b-container>
</template>

<script>
	export default {
		name: 'ImmobilienAnlage',
		components: {},
		data: () => ({ slide: 0, sliding: null, windowWidth: window.innerWidth }),
		methods: {},
		mounted() {
			window.onresize = () => {
				this.windowWidth = window.innerWidth;
			};
		},
	};
</script>
