<template>
	<b-container class="services">
		<div class="img-wrapper">
			<img src="../assets/images/leistungen.jpg" class="w-100" />
		</div>

		<div class="text-wrapper" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000">
			<div class="title-wrapper">
				<h1 style="textAlign: center">{{ $t('leistungen.title') }}</h1>
			</div>
			<!-- <p class="text-intro">
				{{ $t('leistungen.subtitle') }}
			</p> -->
			<p class="text-intro mb-3">{{ $t('leistungen.subtitle-2') }}</p>
			<p class="text-intro">{{ $t('leistungen.subtitle-3') }}</p>
			<router-link to="/contact">
				<b-button class="btn btn-blue btn-big mt-3 mb-5">{{ $t('leistungen.button') }}</b-button>
			</router-link>
			<div class="subtitle-wrapper">
				<h3>{{ $t('leistungen.wirbieten') }}</h3>
			</div>
			<b-row>
				<b-col sm="12" md="4" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000">
					<div class="icon-wrapper">
						<font-awesome-icon :icon="['fas', 'search-dollar']" />
					</div>
					<div>
						<h4>{{ $t('leistungen.item-situation-title') }}</h4>
						<p>
							{{ $t('leistungen.item-situation-text') }}
						</p>
					</div>
				</b-col>
				<b-col sm="12" md="4" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
					<div class="icon-wrapper">
						<font-awesome-icon :icon="['fas', 'hand-holding-usd']" />
					</div>
					<div>
						<h4>{{ $t('leistungen.item-auswert-title') }}</h4>
						<p>
							{{ $t('leistungen.item-auswert-text') }}
						</p>
					</div>
				</b-col>
				<b-col sm="12" md="4" data-aos="fade-up" data-aos-delay="150" data-aos-duration="1000">
					<div class="icon-wrapper">
						<font-awesome-icon :icon="['fa', 'home']" />
					</div>
					<div>
						<h4>{{ $t('leistungen.item-present-title') }}</h4>
						<p>
							{{ $t('leistungen.item-present-text') }}
						</p>
					</div>
				</b-col>
			</b-row>
			<b-row>
				<b-col sm="12" md="4" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
					<div class="icon-wrapper">
						<font-awesome-icon :icon="['fas', 'file-signature']" />
					</div>
					<div>
						<h4>{{ $t('leistungen.item-hilfe-title') }}</h4>
						<p>
							{{ $t('leistungen.item-hilfe-text') }}
						</p>
					</div>
				</b-col>
				<b-col sm="12" md="4" data-aos="fade-up" data-aos-delay="150" data-aos-duration="1000">
					<div class="icon-wrapper">
						<font-awesome-icon :icon="['fas', 'user-friends']" />
					</div>
					<div>
						<h4>{{ $t('leistungen.item-betreuung-title') }}</h4>
						<p>
							{{ $t('leistungen.item-betreuung-text') }}
						</p>
					</div>
				</b-col>
				<b-col sm="12" md="4" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000">
					<div class="icon-wrapper">
						<font-awesome-icon :icon="['fas', 'handshake']" />
					</div>
					<div>
						<h4>{{ $t('leistungen.item-partner-title') }}</h4>
						<p>
							{{ $t('leistungen.item-partner-text') }}
						</p>
					</div>
				</b-col>
			</b-row>
			<router-link to="/contact">
				<b-button class="btn btn-blue btn-big mt-3 mb-5">{{ $t('leistungen.button') }}</b-button>
			</router-link>
		</div>
	</b-container>
</template>
<script>
	export default {
		name: 'Leistungen',
	};
</script>
