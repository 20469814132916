<template>
	<b-container class="main-partner" data-aos="fade">
		<img src="../assets/images/handshake.jpg" class="w-100" />
		<div class="text-wrapper">
			<div class="title-wrapper">
				<h1>{{ $t('home.navigation.links.fifth') }}</h1>
			</div>
		</div>
		<b-row>
			<!-- First Icon -->
			<b-col sm="12" md="4">
				<!-- Mobile Version -->
				<div v-if="windowWidth < 768">
					<div class="icon-wrapper" @click="handleMobileChoice(1)" :class="{ 'is-active': hovering === 1 }">
						<font-awesome-icon :icon="['fas', 'user-tie']" />
					</div>
					<div>
						<h4>Vertriebspartner</h4>
					</div>
				</div>
				<!-- Desktop Version -->
				<div v-else>
					<div class="icon-wrapper" @mouseover="hovering = 1" :class="{ 'is-active': hovering === 1 }">
						<font-awesome-icon :icon="['fas', 'user-tie']" />
					</div>
					<div>
						<h4>Vertriebspartner</h4>
					</div>
				</div>
			</b-col>
			<!-- Second Icon -->
			<b-col sm="12" md="4">
				<!-- Mobile Version -->
				<div v-if="windowWidth < 768">
					<div class="icon-wrapper" @click="handleMobileChoice(2)" :class="{ 'is-active': hovering === 2 }">
						<font-awesome-icon :icon="['fas', 'users']" />
					</div>
					<div>
						<h4>Agenturpartner</h4>
					</div>
				</div>
				<div v-else>
					<div class="icon-wrapper" @mouseover="hovering = 2" :class="{ 'is-active': hovering === 2 }">
						<font-awesome-icon :icon="['fas', 'users']" />
					</div>
					<div>
						<h4>Agenturpartner</h4>
					</div>
				</div>
			</b-col>
			<!-- Third Icon -->
			<b-col sm="12" md="4">
				<!-- Mobile Version -->
				<div v-if="windowWidth < 768">
					<div class="icon-wrapper" @click="handleMobileChoice(3)" :class="{ 'is-active': hovering === 3 }">
						<font-awesome-icon :icon="['fa', 'hard-hat']" />
					</div>
					<div>
						<h4>Bauträger</h4>
					</div>
				</div>
				<!-- Desktop Version -->
				<div v-else>
					<div class="icon-wrapper" @mouseover="hovering = 3" :class="{ 'is-active': hovering === 3 }">
						<font-awesome-icon :icon="['fa', 'hard-hat']" />
					</div>
					<div>
						<h4>Bauträger</h4>
					</div>
				</div>
			</b-col>
		</b-row>
		<div id="display-content" class="display-wrapper">
			<transition name="slide-fade">
				<b-row v-if="hovering === 1" style="align-items: center">
					<b-col sm="12" md="6"><img :src="images.vertriebspartner" class="w-100" /></b-col>
					<b-col sm="12" md="6">
						<div class="subtitle-wrapper">
							<h4>Vertriebspartner</h4>
						</div>
						<p>
							Sie sind Top-Berater, Vertriebsprofi oder wollen dorthin kommen? Wir haben die Eintrittskarte in die Champions League für Sie. Der Preis ist harte Arbeit, Engagement und
							Passion für Ihre Kunden. Wenn Sie immer ein bisschen mehr geben und erst zufrieden sind, wenn Ihre Kunden es sind, dann werden Sie bei uns überdurchschnittliche Erfolge
							erzielen können.
						</p>
						<p class="mt-3">
							Wir bieten Ihnen exzellente Karrierechancen und faire Aufstiegsbedingungen. Bewerben Sie sich noch heute bei uns. Wir freuen uns darauf, Sie kennenzulernen.
						</p>
						<div class="button-wrapper">
							<router-link to="/contact">
								<b-button class="btn btn-blue btn-big mt-3 mb-5">{{ $t('leistungen.button') }}</b-button>
							</router-link>
						</div>
					</b-col>
				</b-row>
			</transition>
			<transition name="slide-fade">
				<b-row v-if="hovering === 2" style="align-items: center">
					<b-col sm="12" md="6"><img :src="images.agenturpartner" class="w-100" /></b-col>
					<b-col sm="12" md="6">
						<div class="subtitle-wrapper">
							<h4>Agenturpartner</h4>
						</div>
						<p>
							Sie haben bereits Kunden die sich brennend für das Thema Immobilien als Kapitalanlage interessieren und suchen ein neues innovatives Produkt für Ihr Portfolio? Dann sind
							Sie bei uns genau richtig! Zahlreiche Agenturen aus der Immobilien, Versicherungs und Finanzdienstleistungsbranche nutzen bereits erfolgreich unser Produkt. Sie benötigen
							nähere Infos? Schreiben Sie uns wir freuen uns auf Sie.
						</p>
						<div class="button-wrapper">
							<router-link to="/contact">
								<b-button class="btn btn-blue btn-big mt-3 mb-5">{{ $t('leistungen.button') }}</b-button>
							</router-link>
						</div>
					</b-col>
				</b-row>
			</transition>
			<transition name="slide-fade">
				<b-row v-if="hovering === 3" style="align-items: center">
					<b-col sm="12" md="6"><img :src="images.bautrager" class="w-100" /></b-col>
					<b-col sm="12" md="6">
						<div class="subtitle-wrapper">
							<h4>Bauträger</h4>
						</div>
						<p>
							Sie sind als Bauträger bzw. Aufteiler tätig oder arbeiten in der Immobilienwirtschaft? Wir sind auf der Suche nach neuen strategischen Partnern. Ob Sie einen effizienten
							Vertrieb für Ihre Immobilien suchen oder wir gemeinsam neue Projekte erfolgreich umsetzen können – sprechen Sie uns an.
						</p>
						<p class="mt-3">Wir freuen uns, Sie bei allen Themen rund um Vermarktung und Vertrieb Ihrer Immobilien an Endkunden unterstützen zu können.</p>
						<div class="button-wrapper">
							<router-link to="/contact">
								<b-button class="btn btn-blue btn-big mt-3 mb-5">{{ $t('leistungen.button') }}</b-button>
							</router-link>
						</div>
					</b-col>
				</b-row>
			</transition>
		</div>
	</b-container>
</template>
<script>
	const agenturpartner = require('../assets/images/agenturpartner.jpg');
	const bautrager = require('../assets/images/bautrager.jpg');
	const vertriebspartner = require('../assets/images/vertriebpartner.jpg');
	export default {
		name: 'Partner',
		data: () => ({
			windowWidth: window.innerWidth,
			hovering: 1,
			vertriebspartner: true,
			bautrager: false,
			agenturpartner: false,
			images: {
				agenturpartner: agenturpartner,
				bautrager: bautrager,
				vertriebspartner: vertriebspartner,
			},
		}),
		methods: {
			handleMobileChoice(val) {
				this.hovering = val;
				this.$scrollTo('#display-content');
			},
		},
		mounted() {
			window.onresize = () => {
				this.windowWidth = window.innerWidth;
			};
		},
	};
</script>
